
import _ from '../../ext/lodash'
import Page from "../page";
import ThemePicker from '../theme-picker'
import localizationStrings from "../../localization";

export default class SettingsPage extends React.Component {

	constructor(props) {
		super(props);
		this.languageCodes = _.uniq(_.map(this.props.allHymnals, hymnal => hymnal.languageCode));
	}

	renderLanguagePreferenceSelect() {
		const optionElements = _.map(this.languageCodes, code => {
			const name = localizationStrings[code].words[code];
			return <option key={code} value={code}>{name}</option>
		});
		optionElements.unshift(<option key={null} value=''>Default</option>); // TODO localize 'default'
		return (
			<select onChange={l => this.setLanguage(l.target.value)}>
				{optionElements}
			</select>
		);
	}

	setLanguage(languageCode) {
		if(languageCode === '') languageCode = null
		const validCode = _.includes(this.languageCodes, languageCode) || languageCode === null;
		this.props.setLanguage(validCode ? languageCode : null);
	}

	render() {
		const words = this.props.languageStrings.words;
		return (
			<Page rootElementId='settings-page' title={words.settings} show={this.props.show} hidePage={this.props.hidePage}>
				<div className='width--full'>{words.theme}: <span className='margin-left--6'><ThemePicker {...this.props} /></span></div>
				<div className='width--full'>{words.languagePreference}: <span className='margin-left--6'>{this.renderLanguagePreferenceSelect()}</span></div>
			</Page>
		);
	}
}
