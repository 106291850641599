
import _ from '../../ext/lodash'
import { CloseButton } from "../../view";
import { buildClassList } from '../../utils/helpers';
import CenteredDiv from "../centered-div";

const providersMetadata = {
	google: {
		name: 'google',
		providerId: 'google.com',
		buttonImageUrl: new URL('../../../resources/images/google-signin-button.png', import.meta.url),
		iconImageUrl: new URL('../../../resources/images/google-square.png', import.meta.url),
	},
	facebook: {
		name: 'facebook',
		providerId: 'facebook.com',
		buttonImageUrl: new URL('../../../resources/images/facebook-signin-button.png', import.meta.url),
		iconImageUrl: new URL('../../../resources/images/facebook-square.png', import.meta.url),
	}
};

export default class AccountPage extends React.Component {

	linkedProviderMetadata() {
		const providerIds = (this.signedIn() && _.map(firebase.auth().currentUser.providerData, 'providerId')) || [];
		return _.filter(providersMetadata, meta => _.includes(providerIds, meta.providerId));
	}

	logout() {
		window.confirm(this.props.languageStrings.phrases.logout) && this.props.logout();
	}

	unlinkProvider(provider) {
		if(this.linkedProviderMetadata().length <= 1) return;
		window.confirm(this.props.languageStrings.phrases.unlinkProvider(provider.name)) && this.props.unlink(provider.providerId);
	}

	unlinkedProviderMetadata() {
		const providerIds = (this.signedIn() && _.map(firebase.auth().currentUser.providerData, 'providerId')) || [];
		return _.reject(providersMetadata, meta => _.includes(providerIds, meta.providerId));
	}

	renderLogout() {
		if(this.signedIn()) return <span className='margin-top--24' onClick={() => this.logout()}>{this.props.languageStrings.words.logout}</span>
	}

	renderSubtitle() {
		if(this.unlinkedProviderMetadata().length === 0) return;
		return (
			<span className='width--full text--center margin-bottom--16'>
				{this.signedIn() ? this.props.languageStrings.phrases.signInToLink : this.props.languageStrings.phrases.signInForFavoritesPage}
			</span>
		)
	}

	renderTitle() {
		return (
			<span className='sign-in-message width--full margin-bottom--8'>
				{this.renderTitleMessage()}
				{this.renderLinkedProviderImages()}
			</span>
		)
	}

	renderTitleMessage() {
		return this.signedIn() ? this.props.languageStrings.words.signedIn : this.props.languageStrings.words.signIn
	}

	renderLinkedProviderImages() {
		return _.map(this.linkedProviderMetadata(), provider => {
			return (
				<img
					key={provider.providerId}
					className='sign-in-provider-icon'
					src={provider.iconImageUrl}
					onClick={() => this.unlinkProvider(provider)}
				/>
			)
		});
	}

	renderSignInOptions() {
		const action = this.signedIn() ? 'link' : 'signIn';
		const relevantProviderMetadata = this.unlinkedProviderMetadata();
		return _.map(relevantProviderMetadata, (provider, i) => {
			const span = i < relevantProviderMetadata.length - 1 && <span className='sign-in-provider-button-separator'>or</span>;
			return (
				<React.Fragment key={provider.providerId}>
					<img
						className='sign-in-provider-button clickable'
						src={provider.buttonImageUrl}
						onClick={() => this.props[action](provider.name)}
					/>
					{ span }
				</React.Fragment>
			)
		});
	}

	signedIn() {
		return !!this.props.user.name;
	}

	render() {
		const classes = { appPage: buildClassList('app-page', {'hidden': !this.props.show})}
		return (
			<div id="account-page" className={classes.appPage}>
				<div className="page-content">
					<div className="page-header">
						{this.renderLogout()}
						<span className="right-title icon-tray">
							<CloseButton onClick={() => this.props.hidePage()} />
						</span>
					</div>
					<div className="page-bottom">
						<CenteredDiv>
							{this.renderTitle()}
							{this.renderSubtitle()}
							{this.renderSignInOptions()}
						</CenteredDiv>
					</div>
				</div>
			</div>
		);
	}
}
