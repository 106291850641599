
export const THEME_COLORS = [
	{ // light
		base: '#EEEEEE',
		secondary: '#424242',
		accents: [
			'#D32F2F',
			'#3FD32F',
			'#2F9FD3',
			'#D3A92F',
			'#942FD4',
		]
	},
	{ // dark
		base: '#424242',
		secondary: '#EEEEEE',
		accents: [
			'#ED1F89',
			'#00C479',
			'#33A0FF',
			'#FFCC33',
			'#B338FF'
		]
	}
];

/*
OLD
DARK
			'#DC1C7F',
			'#00B356',
			'#3099F6',
			'#F6C530',
 */
