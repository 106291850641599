import _ from '../ext/lodash'

const HYMN_SEARCH_WEIGHTS = {
	content: 0.8,
	popularity: 0.2,
};

const DEFAULT_MIN_RANK = 0.05;
const DEFAULT_RESULTS_COUNT = 5;

export function hymn_search(collection, query, popularity, options = {}) {
	const {
		minRank = DEFAULT_MIN_RANK,
		resultCount = DEFAULT_RESULTS_COUNT,
	} = options;

	let ranks = [];
	_.each(collection, hymn => {
		let rank = 0;

		const numberSimilarityRank = string_compare(query, hymn.number);
		const titleSimilarityRank = string_compare(
			_.toLower(toAlphanumeric(_.deburr(query))),
			_.toLower(toAlphanumeric(_.deburr(hymn.name)))
		);
		const highestSimilarityRank = _.max([numberSimilarityRank, titleSimilarityRank])
		rank += highestSimilarityRank * HYMN_SEARCH_WEIGHTS.content;

		const popularityEntry = _.find(popularity, [0, hymn.number]) || [];
		const popularityRank = popularityEntry[1] || 0;
		rank += popularityRank * HYMN_SEARCH_WEIGHTS.popularity;

		// future -> Content, History, Last Category

		const entry = [rank, hymn];
		const entryIndex = _.sortedIndexBy(ranks, entry, 0);
		ranks.splice(entryIndex, 0, entry);
	});

	const topResults = _.slice(_.reverse(ranks), 0, resultCount);
	const approvedResults = _.reject(topResults, ([r]) => r < minRank);

	return approvedResults;
}

function string_compare(str1, str2) { // TODO refactor
	let result = 0;

	var averageChars = (str1.length + str2.length) / 2;
	var averageWords = (str1.split(" ").length + str2.split(" ").length) / 2;

	// Exact Matching 40%
	var totalCharactersMatched = 0
	for(var g = 0; g < str1.length; g++) {
		if(str1[g] != str2[g]) break;
		totalCharactersMatched++;
	}
	result += (totalCharactersMatched / averageChars) * 0.4;

	// Word Matching 40%
	var totalWordsMatched = 0;
	var words1 = _.compact(str1.split(" "))
	var words2 = _.compact(str2.split(" "))
	for(var g = 0; g < words1.length; g++) {
		var indexOf = words2.indexOf(words1[g]);
		if(indexOf != -1) {
			words2.splice(indexOf, 1);
			totalWordsMatched++;
		}
	}
	result += (totalWordsMatched / averageWords) * 0.4;

	// Character Matching 20%
	var searcher = (str1.length > str2.length) ? str2 : str1;
	var searched = (str1.length > str2.length) ? str1 : str2;
	var longestLength = searched.length;
	totalCharactersMatched = 0;
	for(var g = 0; g < searcher.length; g++) {
		var indexOf = searched.indexOf(searcher[g]);
		if(indexOf != -1) {
			searched = searched.slice(indexOf + 1);
			totalCharactersMatched++;
		} else {break}
	}
	result += (totalCharactersMatched / longestLength) * 0.2;

	return result;
}

function toAlphanumeric(str = '') {
	return str.replace(/[^\w\d\s]/g, '')
}

