{
  "name": "sdah",
  "author": "David Ruiz",
  "description": "Simple flexible online sda hymnal",
  "license": "MIT",
  "version": "0.1.0",
  "devDependencies": {
    "@babel/core": "^7.16.5",
    "@babel/preset-env": "^7.16.5",
    "@babel/preset-react": "^7.16.5",
    "@parcel/packager-raw-url": "^2.0.1",
    "@parcel/packager-xml": "^2.0.1",
    "@parcel/transformer-webmanifest": "^2.0.1",
    "@parcel/transformer-xml": "^2.0.1",
    "babel-cli": "^6.26.0",
    "babel-loader": "^7.1.2",
    "babel-minify-webpack-plugin": "^0.2.0",
    "babel-preset-env": "^1.6.1",
    "babel-preset-react": "^6.24.1",
    "parcel": "^2.0.1",
    "webpack": "^3.8.1"
  },
  "scripts": {
    "build-babel": "babel public/js --out-dir public/js1",
    "start-webpack": "webpack --config webpack.config.js",
    "build-webpack": "webpack",
    "build": "clear && npm run build-webpack",
    "watch": "webpack --watch --progress"
  },
  "dependencies": {
    "react": "^16.0.0",
    "react-dom": "^16.0.0"
  }
}
