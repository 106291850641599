
import _ from '../ext/lodash'

export function buildClassList(...collections) {
	return _.uniq(_.flatMap(collections, (collection) => {
		if(_.isArray(collection) || _.isString(collection)) return collection;
		return _.map(_.filter(_.toPairs(collection), 1), 0)
	})).join(' ');
}

