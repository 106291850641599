
import _ from '../ext/lodash'
import HymnEntry from './hymn-entry'

export class HymnListOld extends React.Component {

	// TODO : make efficient

	render() {

		const {
			hymns,
			list,
			favorites = [],
			recents = [],
			sortMethod,
		} = this.props;
		let mappedList = _.compact(list.map(n => hymns[n - 1]));

		if(sortMethod) {

			let method;
			switch(this.props.sortMethod) {
				case 'Latest Viewed':
					method = (a, b) => recents.indexOf(b.number) - recents.indexOf(a.number);
					break;
				case 'Oldest Viewed':
					method = (a, b) => recents.indexOf(a.number) - recents.indexOf(b.number);
					break;
				case 'Alphabetically':
					method = (a, b) => a.name.localeCompare(b.name);
					break;
				case 'Reverse Alphabetically':
					method = (a, b) => b.name.localeCompare(a.name);
					break;
				case 'by Favorites':
					method = (a, b) => favorites.indexOf(b.number) - favorites.indexOf(a.number);
					break;
				case 'by Number Ascending':
					method = (a, b) => a.number - b.number;
					break;
				case 'by Number Descending':
					method = (a, b) => b.number - a.number;
					break;
			}

			mappedList = mappedList.sort(method);

		}

		const entries = mappedList.map(hymn =>
			<HymnEntry
				key={hymn.number}
				number={hymn.number}
				name={hymn.name}
				favorited={favorites.indexOf(hymn.number) !== -1}
				onClick={() => this.props.onClick(hymn.number)}
				onFavorite={() => this.props.onFavorite(hymn.number)} />
		);

		return (
			<div className="hymn-list">
				{entries}
			</div>
		);
	}

}

export default class HymnList extends React.Component {

	// TODO : make efficient

	render() {

		let {
			hymns,
			favorites = [],
			recents = [],
			sortMethod,
		} = this.props;

		if(sortMethod) {

			let method;
			switch(this.props.sortMethod) {
				case 'Latest Viewed':
					method = (a, b) => recents.indexOf(b.number) - recents.indexOf(a.number);
					break;
				case 'Oldest Viewed':
					method = (a, b) => recents.indexOf(a.number) - recents.indexOf(b.number);
					break;
				case 'Alphabetically':
					method = (a, b) => a.name.localeCompare(b.name);
					break;
				case 'Reverse Alphabetically':
					method = (a, b) => b.name.localeCompare(a.name);
					break;
				case 'by Favorites':
					method = (a, b) => favorites.indexOf(b.number) - favorites.indexOf(a.number);
					break;
				case 'by Number Ascending':
					method = (a, b) => a.number - b.number;
					break;
				case 'by Number Descending':
					method = (a, b) => b.number - a.number;
					break;
			}

			hymns = hymns.sort(method);

		}

		const entries = hymns.map((hymn) =>
			<HymnEntry
				key={hymn.number}
				number={hymn.number}
				name={hymn.name}
				favorited={favorites.indexOf(hymn.number) !== -1}
				onClick={() => this.props.onClick(hymn.number)}
				onFavorite={() => this.props.onFavorite(hymn.number)} />
		);

		return (
			<div className="hymn-list">
				{entries}
			</div>
		);
	}

}
