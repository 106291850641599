
import { CloseButton } from '../view'
import { buildClassList } from '../utils/helpers';

export default class Page extends React.Component {

	constructor(props) {
		super(props);
		// this.state = {
		// 	sortMethod: 'Latest Viewed',
		// };
	}

	renderDefaultHeader() {
		return (
			<React.Fragment>
				<span className="page-title">{this.props.title}</span>
				<span className="right-title icon-tray">
					<CloseButton onClick={() => this.props.hidePage()} />
				</span>
			</React.Fragment>
		)
	}

	render() {
		const header = this.props.header || this.renderDefaultHeader();
		const classList = buildClassList('app-page', {'hidden': !this.props.show});

		return (
			<div id={this.props.rootElementId} className={classList}>
				<div className="page-content">
					<div className="page-header">
						{ header }
					</div>
					<div className="page-bottom">
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}
