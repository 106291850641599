
import {FavoriteButton} from '../view'

export default class HymnEntry extends React.Component {

	render() {


		return (
			<div className="hymn-entry" onClick={() => this.props.onClick()} >
				<span className="hymn-entry-number">{this.props.number}</span>
				<span className="hymn-entry-title">{this.props.name}</span>
				<span className="hymn-entry-favorite">
          <FavoriteButton active={this.props.favorited} onClick={() => this.props.onFavorite()} />
        </span>
			</div>
		);
	}
}
