
import _ from '../ext/lodash'
import {buildClassList} from "../utils/helpers";
import { THEME_COLORS } from '../model/colors';


export default class ThemePicker extends React.Component {

	setBase(n) {
		if(!THEME_COLORS[n]) return;
		const [, accent] = this.props.theme;
		this.props.setTheme([n, accent])
	}

	setAccent(n) {
		const [base] = this.props.theme;
		if(!THEME_COLORS[base].accents[n]) return;
		this.props.setTheme([base, n])
	}

	render() {
		const [activeBase, activeAccent] = this.props.theme;
		const baseColors = _.map(THEME_COLORS, (theme, i) => {
			const active = i === activeBase;
			const classes = buildClassList('color-square', {'active': active});
			const onClick = () => this.setBase(i);
			const style = {backgroundColor: theme.base};
			return <span key={i} className={classes} onClick={onClick} style={style}></span>
		});
		const accentColors = _.map(THEME_COLORS[activeBase].accents, (accent, i) => {
			const active = i === activeAccent;
			const classes = buildClassList('color-square', {'active': active});
			const onClick = () => this.setAccent(i);
			const style = {backgroundColor: accent};
			return <span key={i} className={classes} onClick={onClick} style={style}></span>
		});
		return (
			<div className='theme-picker'>
				<span className='theme-picker-base-colors'>{baseColors}</span>
				<span className='theme-picker-divisor'></span>
				<span className='theme-picker-accent-colors'>{accentColors}</span>
			</div>
		);
	}
}
