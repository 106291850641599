
import { MaterialIcon } from '../view'

export default class AccountButton extends React.Component {
	constructor(props) {
		super(props);
	}

	renderProfileImage() {
		return (
			<div className="account-icon clickable" onClick={() => this.props.onClick()} {...this.props}>
				<img src={this.props.user.photoURL} alt={`${this.props.user.name} Profile Image`} />
			</div>
		);
	}

	renderNoAccount() {
		return <MaterialIcon code="person" className="clickable" onClick={() => this.props.onClick()} {...this.props} />;
	}

	render() {
		const imageExists = !!this.props.user.photoURL;
		if(imageExists) {
			return this.renderProfileImage();
		} else {
			return this.renderNoAccount();
		}
	}
}
