
const localizationStrings = {

  'en': {
    words: {

      hymnal: 'Hymnal',
      recents: 'Recents',
      favorites: 'Favorites',
      popular: 'Popular',
      search: 'Search',
      account: 'Account',
      categories: 'Categories',
      settings: 'Settings',
      info: 'Info',

      about: 'About',
      feedback: 'Feedback',
      credits: 'Credits',

      signIn: 'Sign in',
      logout: 'Logout',
      signedIn: 'Signed in',

      theme: 'Theme',
      languagePreference: 'Preferred Language',

      verse: 'Verse',
      refrain: 'Refrain',

      sort: 'Sort',

      en: 'English',

    },

    sortMethods: {
      'Latest Viewed': 'Latest Viewed',
      'Oldest Viewed': 'Oldest Viewed',
      'Alphabetically': 'Alphabetically',
      'Reverse Alphabetically': 'Reverse Alphabetically',
      'by Favorites': 'by Favorites',
      'by Number Ascending': 'by Number Ascending',
      'by Number Descending': 'by Number Descending',
    },

    phrases: {

      logout: 'Are you sure you want to logout?',
      signInForFavorites: 'Sign in to save your favorites',
      signInForFavoritesPage: 'Save your favorites across devices',
      signInToLink: 'Link your data to another account to use either when signing in',
      unlinkProvider: name => `Are you sure you want to unlink ${name}?`,
      search: collection => `Search ${collection}`,


    },

    content: {
      info: {
        about: `This hymnal is all about making songs used for the ministry of God and his word accessible to anyone with an internet connection. As technology increases we get new opportunities to make praise and worship effortless, convenient, sharable and fun.`,
        feedback: `As this hymnal is still in its development phase, there will be more changes to come. All feedback is welcome through the following address.`,
        credits: `Designed and made by David Ruiz, with support from my lovely wife Isabel. Thanks to the open web and powerful open source tools that continue to push the march of technology forward.`
      }

    }

  },

  'es': {
    words: {

      hymnal: 'Himnario',
      recents: 'Recientes',
      favorites: 'Favoritos',
      popular: 'Popular',
      search: 'Busqueda',
      account: 'Cuenta',
      categories: 'Categorias',
      settings: 'Opciones',
      info: 'Info',

      about: 'Descripción',
      feedback: 'Retroalimentación',
      credits: 'Creditos',

      theme: 'Thematica',
      languagePreference: 'Lenguaje Preferido',

      signIn: 'Ingresar',
      logout: 'Salir',
      signedIn: 'Ingresado',

      verse: 'Verso',
      refrain: 'Coro',

      sort: 'Clasificar',

      es: 'Español',

    },

    sortMethods: {
      'Latest Viewed': 'Reciente Vista',
      'Oldest Viewed': 'Antigua Vista',
      'Alphabetically': 'Alfabeticamente',
      'Reverse Alphabetically': 'Alfabeticamente Inverso',
      'by Favorites': 'por Favoritos',
      'by Number Ascending': 'por Numero Acendente',
      'by Number Descending': 'por Numero Decentente',
    },

    phrases: {

      logout: '¿Estas seguro que deseas salir?',
      signInForFavorites: 'Inicia sesión para guardar tus favoritos',
      signInForFavoritesPage: 'Usa tu himnario en cualquier dispositivo',
      signInToLink: 'Conecta tu información a otra cuenta para ingresar con cualquiera',
      unlinkProvider: name => `¿Estas seguro que deseas desconectar ${name}?`,
      search: collection => `Buscar ${collection}`,

    },

    content: {
      info: {
        about: `El propósito de este himnario es hacer disponible himnos usados para el ministerio de Dios y su palabra a todos por medio del uso de el internet. Con el avance continuo de la tecnología se nos presentan nuevas oportunidades para hacer alabanza y adoración fácil, conveniente, compartible e divertido.`,
        feedback: `Mientras que este himnario esté en desarrollamiento, habrán cambios con el tiempo. Toda pregunta, comentario, sugerencia o petición es bienvenido en este correo electrónico:`,
        credits: `Diseñado por David Ruiz, con apoyo de mi querida esposa Isabel. Muchas gracias a todas las herramientas poderosas de código abierto que hacen proyectos así possibles.`
      }

    }

  },


};

export default localizationStrings;
