
import { buildClassList } from '../utils/helpers';

export default class CenteredDiv extends React.Component {
	render() {
		const classes = {
			outerClass: buildClassList('centered-div-outer', this.props.outerClass, this.props.className),
			middleClass: buildClassList('centered-div-middle', this.props.middleClass),
			innerClass: buildClassList('centered-div-inner', this.props.innerClass),
		};
		return (
			<div {...this.props} className={classes.outerClass}>
				<div className={classes.middleClass}>
					<div className={classes.innerClass}>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}
