
import Page from "../page";
import _ from "../../ext/lodash";
import {HymnListOld} from '../hymn-list';
import {buildClassList} from "../../utils/helpers";
import {CloseButton, MaterialIcon} from "../../view";

export default class CategoriesPage extends React.Component {

	constructor(props) {
		super(props);
		this.defaultState = {
			showCategoryHymns: false,
			activeCategoryIndex: 0,
		};
		this.state = this.defaultState;
		this.parseCategories();
	}

	parseCategories() {
		this.categories = _.map(this.props.hymnal.categories.main[1], (categoryName, index) => {
			const hymnIndices = this.props.hymnal.categories.main[0];
			return {
				name: categoryName,
				range: {
					start: hymnIndices[index],
					end: hymnIndices[index + 1]
				}
			}
		});
	}

	showCategoryHymns(categoryIndex) {
		this.setState({
			showCategoryHymns: true,
			activeCategoryIndex: categoryIndex,
		})
	}

	hideCategoryHymns() {
		this.setState({ showCategoryHymns: false });
	}

	renderCategoryList() {
		return (_.map(this.categories, (category, index) => {
			return (
				<span key={category.name} className='categories-list-item width--full clickable' onClick={() => this.showCategoryHymns(index)}>
					<span>{category.range.start + 1}</span>
					<span>{category.name}</span>
				</span>
			);
		}));
	}

	componentDidUpdate(prevProps) {
		if(this.props.hymns !== prevProps.hymns) {
			this.parseCategories();
			this.setState(this.defaultState);
		}
	}

	renderHeader() {
		const classes = {
			upButton: buildClassList('clickable', {'display--none': !this.state.showCategoryHymns})
		};
		return (
			<React.Fragment>
				<span className="page-title">{this.props.title}</span>
				<span className="right-title icon-tray">
					<MaterialIcon className={classes.upButton} code='keyboard_arrow_up' onClick={() => this.hideCategoryHymns()} />
					<CloseButton onClick={() => this.props.hidePage()} />
				</span>
			</React.Fragment>
		)
	}

	render() {
		const activeCategory = this.categories[this.state.activeCategoryIndex];
		const relevantHymnNumbers = [];
		for(let index = activeCategory.range.start; index < activeCategory.range.end; index++)
			relevantHymnNumbers.push(index + 1);
		const classes = {
			categoriesList: buildClassList('categories-list', {'display--none': this.state.showCategoryHymns}),
			categoryHymns: buildClassList({'display--none': !this.state.showCategoryHymns})
		};
		return (
			<Page rootElementId='categories-page' header={this.renderHeader()} {...this.props}>
				<div className={classes.categoriesList}>
					{this.renderCategoryList()}
				</div>
				<div className={classes.categoryHymns}>
					<div className='category-title width--full'>{activeCategory.name}</div>
					<HymnListOld
						hymns={this.props.hymns}
						list={relevantHymnNumbers}
						favorites={this.props.user.favorites}
						recents={this.props.user.recents}
						sortMethod='by Number Ascending'
						onClick={n => this.props.openHymn(n)}
						onFavorite={n => this.props.onFavorite(n)} />
				</div>
			</Page>
		);
	}
}
