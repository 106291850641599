
import _ from '../ext/lodash';

const PAGE_TYPES = {
	other: 0,
	verse: 1,
	refrain: 2,
};
const MAX_LINES_PER_PAGE = 5;

export default class HymnParser {

	constructor(hymn, localeStrings) {
		this.hymn = hymn;
		this.localeStrings = localeStrings;
	}

	fullText() {
		const sections = this.sections();

		let sectionText = [];
		for(let section of sections) {
			const label = this.sectionLabel(section);
			sectionText.push(_.compact([label, ...section.lines]).join('\n'));
		}
		return sectionText.join('\n\n');
	}

	// returns an array to be rendered as pages in the form of:
	// [{ title: '132', subtitle: 'Our Lord', lines: [...], char: '#' }, ...]
	pages() {
		const sections = this.sections();
		let pages = [{
			// initial page
			title: this.hymn.number,
			subtitle: this.hymn.name,
			char: '#',
		}];
		for(let section of sections) {
			const label = this.sectionLabel(section);
			const char = this.sectionChar(section);
			const sectionPages = divideIntoGroupsOfSize(section.lines.length, MAX_LINES_PER_PAGE);
			let linesCovered = 0;
			_.each(sectionPages, pageLines => {
				pages.push({ title: label, lines: section.lines.slice(linesCovered, pageLines+linesCovered), char});
				linesCovered += pageLines;
			})
		}
		// add unique id for react rendering
		_.each(pages, (page, i) => page.id = `${this.hymn.number} -- ${this.hymn.name} -- ${i}`);
		return pages;
	}

	sectionChar(section) {
		const words = this.localeStrings.words;
		switch(section.type) {
			case PAGE_TYPES.refrain:
				return words.refrain[0];
			case PAGE_TYPES.verse:
				return section.number.toString();
			default:
				return '.';
		}
	}

	sectionLabel(section) {
		const words = this.localeStrings.words;
		switch(section.type) {
			case PAGE_TYPES.refrain:
				return words.refrain;
			case PAGE_TYPES.verse:
				return `${words.verse} ${section.number}`;
			default:
				return '';
		}
	}

	// returns an organized digestible array in the form of:
	// [{ type: PAGE_TYPES.verse, lines: [...], number: 2 }, ...]
	sections() {
		const { other, verses, refrain, refrainFirst } = this.hymn;

		if(other) {
			if(other.length > 1) {
				return _.map(other, (lines, i) => ({type: PAGE_TYPES.verse, lines, number: i + 1}));
			} else {
				return [{type: PAGE_TYPES.other, lines: other[0]}];
			}
		} else {
			const verseSections = _.map(verses, (lines, i) => ({type: PAGE_TYPES.verse, lines, number: i + 1}));
			if(!refrain) { return verseSections }
			const refrainSection = {type: PAGE_TYPES.refrain, lines: refrain};
			const sections = [];
			_.each(verseSections, verseSection => {
				if(refrainFirst) {
					sections.push(refrainSection, verseSection);
				} else {
					sections.push(verseSection, refrainSection);
				}
			});
			return sections;
		}
	}

}

function divideIntoGroupsOfSize(count, groupLimit) {
	const numberOfGroups = Math.ceil(count / groupLimit);
	const initialCountPerGroup = Math.floor(count / numberOfGroups);
	const groups = new Array(numberOfGroups).fill(initialCountPerGroup);
	const remainingCount = count % numberOfGroups;
	for(let group = 0; group < remainingCount; group++) groups[group]++;
	return groups;
}
