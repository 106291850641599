
import _ from '../../ext/lodash'
import Page from "../page";
import ThemePicker from '../theme-picker'
import localizationStrings from "../../localization";

export default class InfoPage extends React.Component {

	constructor(props) {
		super(props);
		this.languageCodes = _.uniq(_.map(this.props.allHymnals, hymnal => hymnal.languageCode));
	}

	renderLanguagePreferenceSelect() {
		const optionElements = _.map(this.languageCodes, code => {
			const name = localizationStrings[code].words[code];
			return <option key={code} value={code}>{name}</option>
		});
		optionElements.unshift(<option key={null} value=''>Default</option>); // TODO localize 'default'
		return (
			<select onChange={l => this.setLanguage(l.target.value)}>
				{optionElements}
			</select>
		);
	}

	setLanguage(languageCode) {
		if(languageCode === '') languageCode = null
		const validCode = _.includes(this.languageCodes, languageCode) || languageCode === null;
		this.props.setLanguage(validCode ? languageCode : null);
	}

	render() {
		const { words, content } = this.props.languageStrings;
		return (
			<Page rootElementId='info-page' title={words.info} show={this.props.show} hidePage={this.props.hidePage}>
				<div className='margin-bottom--48 margin-top--12'>
					<h2>{words.about}</h2>
					<p className='white-space--pre-wrap'>{content.info.about}</p>
				</div>
				<div className='margin-bottom--48'>
					<h2>{words.feedback}</h2>
					<p className='white-space--pre-wrap'>{content.info.feedback}</p>
					<br/><a href="mailto:feedback@sdahymns.org">feedback@sdahymns.org</a>
				</div>
				<div className='margin-bottom--48'>
					<h2>{words.credits}</h2>
					<p className='white-space--pre-wrap'>{content.info.credits}</p>
				</div>

			</Page>
		);
	}
}
